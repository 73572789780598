import React from "react";
import Breadcrumb from "../general/Breadcrumb";
import CustomDataGrid from "../general/CustomDataGrid";
import { useDashboard } from "../../context/DashboardContext";
import { useTranslation } from "react-i18next";
import icon from "../../assets/images/no-quotes.svg";

const Quotes = () => {
  const { quotes } = useDashboard();
  const { t } = useTranslation();

  // Function to determine the class name based on status
  const getStatusClassName = (status) => {
    switch (status) {
      case "Paid":
        return "status active-status";
      case "Pending":
        return "status pending-status";
      case "Expired":
        return "status expired-status";
      case "Invoiced":
        return "status invoiced-status";
      default:
        return "status";
    }
  };

  const columns = [
    {
      field: "id",
      headerName: t("quotes.columns.quoteId"),
      width: 245,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "docnum",
      headerName: t("quotes.columns.quoteName"),
      width: 245,
      disableColumnMenu: true,
    },
    {
      field: "customer_long_name",
      headerName: t("quotes.columns.customerName"),
      width: 245,
      disableColumnMenu: true,
    },
    {
      field: "quote_amount_euro",
      headerName: t("quotes.columns.totalAmount"),
      width: 245,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: t("quotes.columns.status"),
      width: 245,
      renderCell: (params) => {
        const className = getStatusClassName(params.row.status);
        return <span className={className}>{params.row.status}</span>;
      },
      disableColumnMenu: true,
      sortable: true,
      valueGetter: (params) => params.row.status,
    },
    {
      field: "actions",
      headerName: "",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <span className="text-blue-100">
            {t("quotes.actions.downloadQuote")}
          </span>
        );
      },
      disableColumnMenu: true,
    },
  ];

  const rows = quotes.map((quote) => ({
    id: quote.id,
    docnum: quote.docnum,
    customer_long_name: quote.customer_long_name,
    quote_amount_euro: quote.quote_amount_euro.toLocaleString("en-US", {
      style: "currency",
      currency: "EUR",
    }),
    status: quote.is_paid ? "Paid" : "Pending",
  }));

  return (
    <>
      <Breadcrumb paths={[{ label: t("quotes.breadcrumb"), link: "" }]} />
      {quotes.length ? (
        <CustomDataGrid
          rows={rows}
          columns={columns}
          enableSearch={true}
          searchFieldName="id, docnum, customer_long_name"
          enablePagination={true}
          pageSizeOptions={[25, 50, 75, 100]}
          pageSize={25}
        />
      ) : (
        <div className="data-grid-no-data">
          <img src={icon} alt="" className="mb-6" />
          <h2 className="font-semibold text-base text-gray-700 mb-2">
            {t("quotes.emptyState.title")}
          </h2>
          <p className="text-base text-gray-500">
            {t("quotes.emptyState.desc")}
          </p>
          <a
            href="https://hex-rays.com/cgi-bin/quote.cgi"
            target="_blank"
            className="btn-primary with-icon mt-6"
            rel="noreferrer"
          >
            <i className="icon-shopping-bag-03" />
            {t("quotes.emptyState.cta")}
          </a>
        </div>
      )}
    </>
  );
};

export default Quotes;
