import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./auth/Login";
import Dashboard from "./dashboard/Dashboard";
import PrivateRoute from './PrivateRoute';

/**
 * AppRoutes component for defining application routes.
 *
 * @returns {JSX.Element} - The rendered AppRoutes component.
 */
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard/*" element={<PrivateRoute element={Dashboard} />} />
      {/* Add other routes as needed */}
    </Routes>
  );
};

export default AppRoutes;
