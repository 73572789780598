import React, { useState, useEffect, useRef } from "react";
import apiService from "../../utils/apiService";
import { useNotification } from "../../context/NotificationContext";
import { sendEvent } from "../../utils/analyticsService";
import { useDashboard } from "../../context/DashboardContext";

const ActionMenuLicenses = ({ id, t, getStatus }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [actionStates, setActionStates] = useState({});
  const actionMenuRef = useRef(null);
  const { addNotification } = useNotification();
  const [row, setRow] = useState({});

  const {
    licenses,
    activeCustomerId
  } = useDashboard();

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsVisible((current) => !current);

    // When opening the menu, dispatch a custom event with the menu ID
    if (!isVisible) {
      const customEvent = new CustomEvent("actionMenuOpened", {
        detail: { menuId: id },
      });
      document.dispatchEvent(customEvent);
    }
  };


  useEffect(() => {
    // Handle clicks outside of the menu to close it
    const handleOutsideClick = (event) => {
      if (
        actionMenuRef.current &&
        !actionMenuRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    // Handle the custom event to close the menu if another menu is opened
    const handleCustomEvent = (event) => {
      if (event.detail.menuId !== id) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("actionMenuOpened", handleCustomEvent);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("actionMenuOpened", handleCustomEvent);
    };
  }, [id]);

  useEffect(() => {

    const currentLicense = licenses[activeCustomerId].find(license => license.pubhash === id);
    setRow(currentLicense);

  }, [activeCustomerId, id, licenses]);

  const handleDownload = async (licenseId, type) => {
    setActionStates((prev) => ({ ...prev, [`${licenseId}-${type}`]: true }));
    try {
      const downloadData = await apiService.downloadInstaller(licenseId, type);
      if (downloadData && downloadData.location) {
        sendEvent(`Download ${type}`, {
          event_trigger: `Click on Download ${type}`,
          event_category: `Download ${type}`,
          event_label: `Download ${type}`,
          page_name: "licenses",
        });

        window.open(downloadData.location, "_blank");
      }
    } catch (error) {
      console.error("Error downloading file:", error.message);
      sendEvent("Error", {
        event_trigger: `Error downloading ${type}`,
        event_category: "Error",
        event_label: `Error downloading ${type}`,
        error_message: error,
      });
      addNotification({
        title: t('licenses.notifications.errorDownloadingFile'),
        message: error.message,
        icon: "icon-alert-circle",
        type: "error",
      });
    } finally {
      setActionStates((prev) => ({ ...prev, [`${licenseId}-${type}`]: false }));
    }
  };

  const handleRequestInstaller = async (licenseId) => {
    setActionStates((prev) => ({
      ...prev,
      [`${licenseId}-requestInstaller`]: true,
    }));

    try {
      await apiService.requestInstaller(licenseId);

      sendEvent("Request installer & license key", {
        event_trigger: "Click on Request installer & license key",
        event_category: "Request installer & license key",
        event_label: "Request installer & license key",
        page_name: "licenses",
      });

      addNotification({
        title: t('licenses.notifications.installerRequestedTitle', {licenseId}),
        message: t('licenses.notifications.installerRequestedDesc'),
        icon: "icon-check-circle",
        type: "success",
      });

    } catch (error) {
      sendEvent("Error", {
        event_trigger: "Error requesting installer & license key",
        event_category: "Error",
        event_label: "Error requesting installer & license key",
        error_message: error,
      });
      addNotification({
        title: t('licenses.notifications.installerRequestedFailedTitle', {licenseId}),
        message: t('licenses.notifications.installerRequestedFailedDesc'),
        icon: "icon-alert-circle",
        type: "error",
      });
    } finally {
      setActionStates((prev) => ({
        ...prev,
        [`${licenseId}-requestInstaller`]: false,
      }));
    }
  };

  const handleCopyPassword = async (password) => {
    // Check if the password is undefined or empty
    if (password === undefined || password === "") {
      addNotification({
        title: t('licenses.notifications.passwordErrorTitle'),
        message: t('licenses.notifications.passwordErrorDesc'),
        icon: "icon-alert-circle",
        type: "error",
      });
      return;
    }

    try {
      await navigator.clipboard.writeText(password);

      sendEvent("Copy installation password", {
        event_trigger: "Click on Copy installation password",
        event_category: "Copy installation password",
        event_label: "Copy installation password",
        page_name: "licenses",
      });

      addNotification({
        title: t('licenses.notifications.passwordSuccessTitle'),
        message: t('licenses.notifications.passwordSuccessDesc'),
        icon: "icon-check-circle",
        type: "success",
      });
    } catch (error) {
      console.error("Error copying password:", error.message);

      sendEvent("Error", {
        event_trigger: "Error copying password",
        event_category: "Error",
        event_label: "Error copying password",
        error_message: error.message,
      });

      addNotification({
        title: t('licenses.notifications.passwordErrorTitle'),
        message: t('licenses.notifications.passwordErrorDesc'),
        icon: "icon-alert-circle",
        type: "error",
      });
    }
  };

  const renderButton = (disabled, onClick, text, processingText) => (
    <button
      disabled={disabled}
      onClick={onClick}
      className="block w-full text-left px-4 py-2 text-sm rounded-md text-gray-700 hover:bg-gray-300 transition ease-in-out duration-200"
    >
      {disabled ? processingText : text}
    </button>
  );

  const renderDefaultText = (text) => (
    <div className="block w-full text-left px-4 py-2 text-sm rounded-md text-gray-500 cursor-default">
      {text}
    </div>
  );

  const renderContactSalesButton = () => {
    const link = <a
      href={`mailto:sales@hex-rays.com?subject=license%20renewal%20request&body=Hello,%0AI would like to receive a quote to renew the license ${id}.%0AThank you.%0A%0A------- Additional comments below this line -------`}
      className="block w-full text-left px-4 py-2 text-sm rounded-md text-gray-700 hover:bg-gray-300 transition ease-in-out duration-200"
      onClick={() =>
        sendEvent("Contact sales", {
          event_trigger: "Click on Contact sales",
          event_category: "Contact sales",
          event_label: "Contact sales",
          page_name: "licenses",
        })
      }
    >
      {t("licenses.contactSales")}
    </a>;
    return link;
  };

  const renderContactSupportButton = () => {
    const link = <a
      href="mailto: my@hex-rays.com"
      className="block w-full text-left px-4 py-2 text-sm rounded-md text-gray-700 hover:bg-gray-300 transition ease-in-out duration-200"
      onClick={() =>
        sendEvent("Contact sales", {
          event_trigger: "Click on Cntact support",
          event_category: "Contact support",
          event_label: "Contact support",
          page_name: "licenses",
        })
      }
    >
      {t("licenses.emptyState.cta")}
    </a>;
    return link;
  };

  const isActiveOrExpiring =
    getStatus(row.support).status.toLowerCase() === "active" ||
    getStatus(row.support).status.toLowerCase() === "about to expire";

  const isExpired =
    getStatus(row.support).status.toLowerCase() === "expired";

  const isExpiring =
    getStatus(row.support).status.toLowerCase() === "about to expire";

  const isInactive =
    getStatus(row.support).status.toLowerCase() === "inactive";

  return (
    <div className="relative inline-block text-left" ref={actionMenuRef}>
      <i
        className={`icon icon-dots-vertical action-menu cursor-pointer transition ease-in-out duration-200 rounded-full hover:bg-gray-300 text-gray-500 p-2 text-xl leading-1 ${
          isVisible ? "bg-gray-300" : ""
        }`}
        onClick={toggleMenu}
        data-testid="action-icon"
      />
      {isVisible && isActiveOrExpiring && (
        <div className="absolute right-0 z-10 mt-2 min-w-56 w-auto origin-top-right bg-white border border-gray-300 divide-y divide-gray-100 rounded-md outline-none p-4 actions-menu">
          <div>
            {((row.s3_indexer && row.s3_indexer.length === 0) || 
              (row.s3_indexer.length > 1 && 
              row.s3_indexer[0].updated_at !== null && 
              (new Date(row.s3_indexer[0].updated_at).getTime() < new Date(row.updated_at).getTime()
                || row.suffix !== row.s3_indexer[0].suffix
              )
              )) 
              ? (
              <>
                {renderButton(
                  actionStates[`${id}-requestInstaller`],
                  (e) => {
                    e.stopPropagation();
                    handleRequestInstaller(id);
                  },
                  t("licenses.requestInstaller"),
                  "Processing..."
                )}
                {renderDefaultText(t("licenses.downloadInstaller"))}
                {renderDefaultText(t("licenses.downloadLicenseKey"))}
                {renderDefaultText(t("licenses.copyPassword"))}
              </>
            ) : row.s3_indexer &&
              row.s3_indexer.some((s3) => s3.updated_at === null) ? (
              <>
                {renderDefaultText(t("licenses.buildingInProgress"))}
                {renderDefaultText(t("licenses.downloadInstaller"))}
                {renderDefaultText(t("licenses.downloadLicenseKey"))}
                {renderDefaultText(t("licenses.copyPassword"))}
              </>
            ) : 

            ((row.s3_indexer && row.s3_indexer.length > 0) || 
              (row.s3_indexer[0].updated_at !== null && 
              (new Date(row.s3_indexer[0].updated_at).getTime() > new Date(row.updated_at).getTime()
                || row.suffix === row.s3_indexer[0].suffix
              )
              )) 
            ?
            (
              <>
                {renderDefaultText(t("licenses.requestInstaller"))}
                {renderButton(
                  actionStates[`${id}-installer`],
                  (e) => {
                    e.stopPropagation();
                    handleDownload(id, "installer");
                  },
                  t("licenses.downloadInstaller"),
                  "Processing..."
                )}
                {renderButton(
                  actionStates[`${id}-key`],
                  (e) => {
                    e.stopPropagation();
                    handleDownload(id, "key");
                  },
                  t("licenses.downloadLicenseKey"),
                  "Processing..."
                )}
                {renderButton(
                  actionStates[`${id}-password`],
                  (e) => {
                    e.stopPropagation();
                    handleCopyPassword(row.password);
                  },
                  t("licenses.copyPassword"),
                  t("licenses.copyingPassword")
                )}
              </>
            ) : renderContactSupportButton()}
            {isExpiring && renderContactSalesButton()}
          </div>
        </div>
      )}
      {isVisible && (isExpired || isInactive) && (
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right bg-white border border-gray-300 divide-y divide-gray-100 rounded-md outline-none p-4 actions-menu">
          <div>{renderContactSalesButton()}</div>
        </div>
      )}
    </div>
  );
};

export default ActionMenuLicenses;
