import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import heroImage from "../../assets/images/auth-hero.jpg";
import logo from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

/**
 * The Login component responsible for user authentication.
 *
 * @returns {JSX.Element} - The login form and UI.
 */
const Login = () => {
  const { signIn, verifyOtp, resendOtp } = useAuth();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(null);
  const [touched, setTouched] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(true);
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const inputsRef = useRef([]);
  const { t } = useTranslation();

  /**
   * Checks if the provided email is valid.
   *
   * @param {string} email - The email address to validate.
   * @returns {boolean} - `true` if the email is valid, otherwise `false`.
   */
  const isValidEmail = (email) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  /**
   * Handles changes in the email input field.
   *
   * @param {object} e - The event object.
   */
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.length === 0) {
      setTouched(false);
      setEmailError(null);
    } else if (!touched) {
      setTouched(true);
    }
  };

  /**
   * Handles blur event of the email input field.
   */
  const handleEmailBlur = () => {
    if (!isValidEmail(email) && email) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError(null);
    }
  };

  /**
   * Handles changes in the OTP (One-Time Password) input fields.
   *
   * @param {object} element - The input element.
   * @param {number} index - The index of the input field.
   */
  const handleCodeChange = (element, index) => {
    let newCode = code.split("");
    if (/^\d$/.test(element.value)) {
      newCode[index] = element.value;
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    } else {
      newCode[index] = "";
      if (element.previousSibling && !element.value) {
        element.previousSibling.focus();
      }
    }
    newCode = newCode.join("");
    setCode(newCode);
  };

  /**
   * Handles paste event for OTP input fields.
   *
   * @param {object} e - The paste event object.
   */
  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData
      .getData("text")
      .replace(/\D/g, "")
      .slice(0, 6);
    setCode(paste);
    inputsRef.current.forEach((input, index) => {
      input.value = paste[index] || "";
    });
    const nextIndex = paste.length < 6 ? paste.length : 5;
    inputsRef.current[nextIndex].focus();
  };

  /**
   * Handles the login form submission.
   *
   * @param {object} e - The form submission event object.
   */
  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError(null);
    setIsLoading(true);

    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    const response = await signIn(email);

    if (response.error) {
      setEmailError(response.error.message);
      setCodeError(null);
      setIsLoading(false);
    } else {
      setShowEmailForm(false);
      setShowCodeForm(true);
    }

    setIsLoading(false);
  };

  /**
   * Handles the OTP verification form submission.
   *
   * @param {object} e - The form submission event object.
   */
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setCodeError(null);
    setIsLoading(true);
    const response = await verifyOtp(email, code);
    if (response.error) {
      setCodeError(response.error.message);
      setEmailError(null);
    }
    setIsLoading(false);
  };

  /**
   * Handles the OTP resend button click.
   */
  const handleResendOtp = async () => {
    setIsResending(true);
    const response = await resendOtp(email);
    if (response.error) {
      setEmailError(null);
      setCodeError(response.error.message);
    } else {
      setShowTimer(true);
    }
    setIsResending(false);
  };

  useEffect(() => {
    let interval;
    if (showTimer && timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer === 0) {
      setShowTimer(false);
      setTimer(60);
    }
    return () => clearInterval(interval);
  }, [timer, showTimer]);

  /**
   * Function to navigate back to the email login form.
   */
  const backToLogin = () => {
    setShowEmailForm(true);
    setShowCodeForm(false);
  };

  return (
    <>
      <div className="flex min-h-full flex-1 h-screen grid grid-cols-1 md:grid-cols-2">
        <div className="flex flex-1 flex-col justify-center px-6 py-20 sm:px-6 lg:flex-none lg:px-20 xl:px-24 relative">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-14 w-auto mb-9" src={logo} alt="Hex-Rays" />
              {showEmailForm && (
                <>
                  <h2 className="text-2xl font-bold leading-8 text-gray-700">
                    {t("login.signInToYourAccount")}
                  </h2>
                  <p className="mt-2 text-sm text-gray-600 font-normal">
                    {t("login.signInDesc")}
                  </p>
                  <div className="mt-10">
                    <form onSubmit={handleLogin}>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-normal text-gray-500"
                        >
                          {t("general.email")}
                        </label>

                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            placeholder={t("login.emailPlaceholder")}
                            required
                            className={`${
                              touched && !isValidEmail(email) && "error"
                            } ${touched && isValidEmail(email) && "valid"} `}
                            value={email}
                            onChange={handleEmailChange}
                            onBlur={handleEmailBlur}
                          />
                        </div>
                      </div>
                      {emailError && (
                        <p className="text-red-500 text-xs mt-2.5">
                          {emailError}
                        </p>
                      )}
                      <div>
                        <button
                          type="submit"
                          className={`btn-primary mt-6 ${
                            isLoading ? "loading" : ""
                          }`}
                          disabled={!isValidEmail(email) || isLoading}
                        >
                          {!isLoading ? (
                            <span className="text">{t("login.signIn")}</span>
                          ) : (
                            <span className="loader" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )}
              {showCodeForm && (
                <>
                  <h2 className="text-2xl font-bold leading-8 text-gray-700">
                    {t("login.checkYourEmail")}
                  </h2>
                  <p className="mt-2 text-sm text-gray-600 font-normal">
                    <Trans
                      i18nKey="login.verificationCodeSent"
                      components={[<span className="underline"></span>]}
                      values={{ email: email }}
                    />
                  </p>
                  <div className="mt-10">
                    <form
                      onSubmit={handleVerifyOtp}
                      className="verify-code-form"
                    >
                      <div className="flex space-x-2 justify-center">
                        {Array.from({ length: 6 }).map((_, index) => (
                          <input
                            key={index}
                            ref={(ref) => (inputsRef.current[index] = ref)}
                            type="number"
                            id={`digit-${index}`}
                            name={`digit-${index}`}
                            maxLength="1"
                            placeholder="0"
                            className={`w-14 h-20 text-center form-input ${
                              code.length === 6 && !codeError ? "valid" : ""
                            } ${codeError ? "error" : ""}`}
                            value={code[index] || ""}
                            onChange={(e) => handleCodeChange(e.target, index)}
                            onPaste={handlePaste}
                          />
                        ))}
                      </div>

                      {codeError && (
                        <p className="text-red-500 text-xs mt-2.5">
                          {codeError}
                        </p>
                      )}

                      <div>
                        <button
                          type="submit"
                          className={`btn-primary mt-6 ${
                            isLoading ? "loading" : ""
                          }`}
                          disabled={code.length !== 6 || isLoading}
                        >
                          {!isLoading ? (
                            <span className="text">
                              {t("login.verifyCode")}
                            </span>
                          ) : (
                            <span className="loader" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="text-center mt-9">
                    <p className="text-base font-normal text-gray-500">
                      {showTimer
                        ? `Code sent. Request new in ${timer} sec`
                        : "Didn’t receive the email? "}
                      {!showTimer && isResending && "Loading ..."}
                      {!showTimer && !isResending && (
                        <span
                          className="text-blue-100 underline cursor-pointer font-medium"
                          onClick={handleResendOtp}
                        >
                          {t("login.clickToResend")}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="text-center mt-9">
                    <p
                      className="text-base font-normal text-gray-700 flex content-center justify-center gap-3 cursor-pointer"
                      onClick={(e) => backToLogin()}
                    >
                      <span className="icon icon-arrow-narrow-left text-xl" />
                      <span>{t("login.backToLogin")}</span>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="footer absolute flex align-center justify-center gap-3 left-0 bottom-6 px-4 w-full flex-wrap sm:bottom-6">
            <p className="text-base text-gray-600">
              <Trans
                i18nKey="footer.copyrights"
                values={{ year: new Date().getFullYear() }}
              />
            </p>
            <a
              href="https://hex-rays.com/privacy_policy/"
              className="text-base underline transition ease-in-out duration-200 hover:opacity-50 text-gray-600"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.privacyPolicy")}
            </a>
            <a
              href="https://hex-rays.com/terms_of_use/"
              className="text-base underline transition ease-in-out duration-200 hover:opacity-50 text-gray-600"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.termsAndConditions")}
            </a>
            {/* <a
              href="/"
              className="text-base underline transition ease-in-out duration-200 hover:opacity-50 text-gray-600"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.cookiePolicy")}
            </a> */}
          </div>
        </div>
        <div className="hidden md:flex flex-1 flex-col justify-center p-4">
          <div className="bg-gray-200 h-full rounded-2xl relative overflow-hidden">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={heroImage}
              alt="State-of-the-art binary code analysis tools"
            />
            <div className="blur-box">{t("login.heroText")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
