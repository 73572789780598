import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

/**
 * PrivateRoute component for protecting routes that require authentication.
 *
 * @param {object} props - Props for the PrivateRoute component.
 * @param {JSX.Element} props.element - The element to render if the user is authenticated.
 * @param {object} props.rest - Additional props to pass to the rendered element.
 * @returns {JSX.Element} - The rendered PrivateRoute component.
 */
const PrivateRoute = ({ element: Element, ...rest }) => {
  const { user } = useAuth();
  const location = useLocation();

  return user ? <Element {...rest} /> : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
