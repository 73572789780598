// Maintenance.js
import React from "react";
import logo from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";

const Maintenance = () => {

    const { t } = useTranslation();

  return (
    <div className="maintenante w-full h-full flex p-5 justify-center justify-items-center dotted-background">
      <div className="flex justify-center flex-col w-96 text-center">
        <img
          className="h-13 w-auto mx-auto"
          src={logo}
          alt="My Hex-Rays Account"
        />
        <h1 className="mt-16 mb-2 text-xl">
          {t('maintenance.title')}
        </h1>
        <p className="text-base text-gray-500">
          {t('maintenance.description')}
        </p>
        <a href="https://hex-rays.com/" className="btn-primary mt-16">
          {t('maintenance.cta')}
        </a>
        <a
          href="mailto:sales@hex-rays.com"
          className="mt-6 text-base underline transition ease-in-out duration-200 hover:opacity-50 text-gray-600"
        >
          {t('maintenance.contactSales')}
        </a>
      </div>
    </div>
  );
};

export default Maintenance;
