import React, { createContext, useContext, useState, useEffect } from "react";
import supabase from "../utils/supabase";
import { useNavigate, useLocation } from "react-router-dom";
import { useNotification } from "./NotificationContext";
import { sendEvent } from '../utils/analyticsService';

const AuthContext = createContext();

const validUrls = [
  "/dashboard/licenses",
  // "/dashboard/quotes"
];

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    user: null,
    loading: true,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { addNotification } = useNotification();
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [userSession, setUserSession] = useState(false);

  const updateAuthState = (newState) => {
    setAuthState(prevState => ({ ...prevState, ...newState }));
  };

  useEffect(() => {
    async function checkSession() {
      try {
        const sessionResponse = await supabase.auth.getSession();
        setUserSession(sessionResponse.data?.session);
        if (sessionResponse.error) {
          throw sessionResponse.error;
        }

        let newUser;
        if (sessionResponse.data?.session) {
          newUser = sessionResponse.data.session.user;
        }
        updateAuthState({ user: newUser, loading: false });
      } catch (error) {
        addNotification({
          title: "Error",
          message: error.message || "An error occurred during authentication.",
          icon: "icon-alert-circle",
          type: "error",
        });
        updateAuthState({ user: null, loading: false });
      }
    }

    checkSession();

  }, [addNotification, triggerEffect]);

  useEffect(() => {
    if (!authState.loading) {
      if (authState.user) {
        const isDashboardRoute = validUrls.includes(location.pathname);
        if (!isDashboardRoute) {
          navigate("/dashboard/licenses");
        }
      } else if (!authState.user && location.pathname !== "/login") {
        navigate("/login");
      }
    }
  }, [authState.user, authState.loading, navigate, location.pathname]);

  /**
   * Sign in a user using email.
   *
   * @param {string} email - The user's email address.
   * @returns {Object} - An object containing success or error information.
   */
  const signIn = async (email) => {
    try {
      const { error } = await supabase.auth.signInWithOtp({ email });
      if (error) {

        sendEvent("Error", {
          event_trigger: "Request magic link",
          event_category: "Error",
          event_label: "Error getting magic link",
          error_message: error,
        });

        switch (error.status) {
          case 400:
            return { error };
          case 401:
          case 403:
            addNotification({
              title: `Error - ${error.status}`,
              message: "Authorization error. Please check your credentials.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
          case 404:
            addNotification({
              title: `Error - ${error.status}`,
              message: "Requested resource not found.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
          case 500:
          case 502:
          case 503:
            addNotification({
              title: `Error - ${error.status}`,
              message: "Server error occurred. Please try again later.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
          default:
            addNotification({
              title: `Error - ${error.status}`,
              message: "An unexpected error occurred.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
        }
      }
      sendEvent("Request magic link", {
        event_trigger: "Click on Request magic link",
        event_category: "Request magic link",
        event_label: "Request magic link",
        page_name: "login",
      });
      return { success: true };
    } catch (error) {
      addNotification({
        title: "Error",
        message: "An unexpected error occurred.",
        icon: "icon-alert-circle",
        type: "error",
      });
      sendEvent("Error", {
        event_trigger: "Request magic link",
        event_category: "Error",
        event_label: "An unexpected error occurred requesting magic link",
        error_message: error,
      });
      return { error };
    }
  };

  /**
   * Verify OTP (One-Time Password) for email-based authentication.
   *
   * @param {string} email - The user's email address.
   * @param {string} token - The OTP token.
   * @returns {Object} - An object containing user data or error information.
   */
  const verifyOtp = async (email, token) => {
    try {
      const { data, error } = await supabase.auth.verifyOtp({
        email,
        token,
        type: "email",
      });
      if (error) {

        sendEvent("Error", {
          event_trigger: "Verify otp",
          event_category: "Error",
          event_label: "Error verify otp",
          error_message: error,
        });

        switch (error.status) {
          case 400:
            return { error };
          case 401:
          case 403:
            return { error };
          case 404:
            addNotification({
              title: "Error",
              message: "Requested OTP not found.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
          case 500:
          case 502:
          case 503:
            addNotification({
              title: "Error",
              message:
                "Server error occurred during OTP verification. Please try again later.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return {};
          default:
            addNotification({
              title: "Error",
              message: "An unexpected error occurred during OTP verification.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
        }
      }
      updateAuthState({ user: data.session.user});
      setTriggerEffect((prevTrigger) => !prevTrigger);
      sendEvent("Login in portal", {
        event_trigger: "Click on Login in portal",
        event_category: "Login in portal",
        event_label: "Login in portal",
        page_name: "login",
      });
      navigate("/dashboard");
      return { user: data.session.user };
    } catch (error) {
      addNotification({
        title: "Error",
        message: "An unexpected error occurred.",
        icon: "icon-alert-circle",
        type: "error",
      });
      sendEvent("Error", {
        event_trigger: "Login in portal",
        event_category: "Error",
        event_label: "An unexpected error occurred during login",
        error_message: error,
      });
      return { error };
    }
  };

  /**
   * Resend OTP (One-Time Password) for email-based authentication.
   *
   * @param {string} email - The user's email address.
   * @returns {Object} - An object containing success or error information.
   */
  const resendOtp = async (email) => {
    try {
      const { error } = await supabase.auth.signInWithOtp({ email });
      if (error) {

        sendEvent("Error", {
          event_trigger: "Resend otp",
          event_category: "Error",
          event_label: "Error resend otp",
          error_message: error,
        });

        switch (error.status) {
          case 400:
            addNotification({
              title: "Error - 400",
              message: "Type provided requires an email address",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
          case 401:
          case 403:
            addNotification({
              title: "Error",
              message: "Authorization error during OTP resend.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
          case 404:
            addNotification({
              title: "Error",
              message: "Email for OTP resend not found.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
          case 500:
          case 502:
          case 503:
            addNotification({
              title: "Error",
              message:
                "Server error occurred during OTP resend. Please try again later.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return {};
          default:
            addNotification({
              title: "Error",
              message: "An unexpected error occurred during OTP resend.",
              icon: "icon-alert-circle",
              type: "error",
            });
            return { error };
        }
      }

      sendEvent("Resend magic link", {
        event_trigger: "Click on Resend magic link",
        event_category: "Resend magic link",
        event_label: "Resend magic link",
        page_name: "login",
      });

      return { success: true };
    } catch (error) {
      addNotification({
        title: "Error",
        message: "An unexpected error occurred.",
        icon: "icon-alert-circle",
        type: "error",
      });
      sendEvent("Error", {
        event_trigger: "Resend otp",
        event_category: "Error",
        event_label: "An unexpected error occurred during OTP resend.",
        error_message: error,
      });
      return { error };
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error("Error signing out:", error.message);
      }

      sendEvent("Logout", {
        event_trigger: "Click on Logout",
        event_category: "Logout",
        event_label: "Logout",
        page_name: "dashbord",
      });
      
      updateAuthState({ user: null });
      navigate("/login");
    } catch (error) {
      console.error("Unexpected error during sign out:", error);
      sendEvent("Error", {
        event_trigger: "Logout",
        event_category: "Error",
        event_label: "Unexpected error during sign out",
        error_message: error,
      });
    }
  };


  return (
    <AuthContext.Provider value={{ 
      user: authState.user, 
      signIn, 
      signOut,
      verifyOtp,
      resendOtp,
      userSession
    }}>
      {!authState.loading ? children : (
        null
      )}
    </AuthContext.Provider>
  );
};

/**
 * Custom hook for accessing the authentication context.
 *
 * @returns {Object} - The authentication context.
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
