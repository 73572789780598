import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import './utils/i18n';  // Import for internationalization (i18n)
import { NotificationProvider } from "./context/NotificationContext";
import { AuthProvider } from "./context/AuthContext";
import { DashboardProvider } from "./context/DashboardContext";
import Notification from "./components/general/Notification";
import AppRoutes from "./components/Routes";
import Maintenance from "./components/dashboard/Maintenance"

// The main component of the application.
const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

const App = () => {

  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === 'development') {
      const meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'noindex, nofollow';
      document.head.appendChild(meta);
      return () => {
        document.head.removeChild(meta);
      };
    }
  }, []);

  return isMaintenanceMode ? (
    <Maintenance/>
  ) : (
    <Router>
      <NotificationProvider>
        <Notification />
        <AuthProvider>
          <DashboardProvider>
            <AppRoutes />
          </DashboardProvider>
        </AuthProvider>
      </NotificationProvider>
    </Router>
  );
};

export default App;
