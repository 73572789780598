import supabase from "./supabase";

/**
 * Object containing various API service functions for fetching data from Supabase.
 */
const apiService = {
  /**
   * Fetches profile customer links from Supabase.
   *
   * @returns {Array} - An array of profile customer links.
   * @throws {Error} - Throws an error if there's an issue fetching data.
   */
  getProfileCustomerLink: async () => {
    let { data, error, status } = await supabase
      .from("profile_customer_link")
      .select();

    if (error && status !== 406) {
      throw error;
    }

    return data || [];
  },

  /**
   * Fetches customer data from Supabase.
   *
   * @returns {Array} - An array of customers.
   * @throws {Error} - Throws an error if there's an issue fetching data.
   */
  getCustomers: async () => {
    let { data, error, status } = await supabase
      .from("enriched_customers")
      .select();

    if (error && status !== 406) {
      throw error;
    }

    return data || [];
  },

  /**
   * Fetches licenses associated with a specific customer from Supabase.
   *
   * @param {string} customerId - The ID of the customer for which to fetch licenses.
   * @returns {Array} - An array of licenses.
   * @throws {Error} - Throws an error if there's an issue fetching data.
   */
  getLicenses: async (customerId) => {
    const limit = 1000; // Maximum number of rows per request
    let hasMore = true;
    let page = 0; // Current page index (0-based)
    let fetchedData = [];

    while (hasMore) {
      const startIndex = page * limit;
      const endIndex = (page + 1) * limit - 1;

      const response = await supabase
        .from("enriched_licenses")
        .select(
          "pubhash, product, user, support, customer_id, family, edition, license_type, password, updated_at, suffix, s3_indexer(pubhash, updated_at, type, suffix)",
          { count: "exact" }
        )
        .eq("customer_id", customerId)
        .neq("family", "DECOMPILER")
        // .is("idalid", null)
        .range(startIndex, endIndex);

      const { data, error, count } = response;

      if (error) {
        throw error;
      }

      if (data) {
        fetchedData = [...fetchedData, ...data]; // Accumulate the fetched data without filtering
      }

      // Determine if there's more data to fetch
      hasMore = count > fetchedData.length;
      page++; // Move to the next page
    }

    // Apply filtering to the entire dataset after fetching is complete
    const filteredData = fetchedData.map((license) => {
      license.s3_indexer = license.s3_indexer.filter(
        (s3obj) => s3obj.suffix === license.suffix
      );
      return license;
    });

    return filteredData; // Return the filtered data
  },

  getDecompilers: async (customerId, idalid) => {
    const limit = 1000;
    let hasMore = true;
    let page = 0;
    let fetchedData = [];

    while (hasMore) {
      const startIndex = page * limit;
      const endIndex = (page + 1) * limit - 1;

      const response = await supabase
        .from("enriched_licenses")
        .select(
          "pubhash, product, user, support, suffix, customer_id, product_code_nos_nol",
          { count: "exact" }
        )
        .eq("customer_id", customerId)
        .eq("idalid", idalid)
        .eq("family", "DECOMPILER")
        .range(startIndex, endIndex);

      const { data, error, count } = response;

      if (error) {
        throw error;
      }

      if (data) {
        fetchedData = [...fetchedData, ...data];
      }

      hasMore = count > fetchedData.length;
      page++;
    }
    return fetchedData;
  },

  /**
   * Fetches quotes data from Supabase.
   *
   * @param {string} customerId - The ID of the customer for which to fetch licenses.
   * @returns {Array} - An array of quotes.
   * @throws {Error} - Throws an error if there's an issue fetching data.
   */
  getQuotes: async (customerId) => {
    let { data, error, status } = await supabase
      .from("enriched_quotes")
      .select()
      .eq("customer_id", customerId);

    if (error && status !== 406) {
      throw error;
    }

    return data || [];
  },

  downloadInstaller: async (licenseId, type) => {
    const queryParams = new URLSearchParams({
      licenseId,
      type: type,
    }).toString();

    let { data, error, status } = await supabase.functions.invoke(
      `s3-download?${queryParams}`
    );

    if (error && status !== 406) {
      throw error;
    }

    return data || [];
  },

  requestInstaller: async (licenseId) => {
    const queryParams = new URLSearchParams({
      licenseId,
    }).toString();

    let { data, error, status } = await supabase.functions.invoke(
      `generate?${queryParams}`
    );

    if (error && status !== 406) {
      throw error;
    }

    return data || [];
  },
};

export default apiService;
