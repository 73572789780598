import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../general/Breadcrumb";
import CustomDataGrid from "../general/CustomDataGrid";
import { useDashboard } from "../../context/DashboardContext";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import icon from "../../assets/images/no-licenses.svg";
import ActionMenuLicenses from "./ActionMenuLicenses";

const Licenses = () => {
  const { t } = useTranslation();
  // Accessing the dashboard context to use licenses, decompilers, etc.
  const {
    licenses,
    activeCustomerId,
    fetchDecompilers,
    licensesLoading,
    decompilers,
    customers,
  } = useDashboard();

  const { user } = useAuth();

  // State for managing the list of licenses to display
  const [rows, setRows] = useState([]);

  // Determines the status of a license based on its support date
  const getStatus = (supportDate) => {
    const currentDate = new Date();
    const endDate = new Date(supportDate);
    const oneMonthAhead = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );
    const threeMonthsAgo = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 3,
      currentDate.getDate()
    );

    let tooltipText = ""; // Initialize tooltipText here

    if (endDate < threeMonthsAgo) {
      tooltipText = t("datagrid.statuses.inactiveTooltip");
      return {
        status: t("datagrid.statuses.inactive"),
        className: "inactive-status",
        tooltipText,
      };
    } else if (endDate < currentDate) {
      tooltipText = t("datagrid.statuses.expiredTooltip");
      return {
        status: t("datagrid.statuses.expired"),
        className: "expired-status",
        tooltipText,
      };
    } else if (endDate < oneMonthAhead) {
      const diffTime = Math.abs(endDate - currentDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      tooltipText = t("datagrid.statuses.aboutToExpireTooltip", {
        days: diffDays,
      });
      return {
        status: t("datagrid.statuses.aboutToExpire"),
        className: "about-to-expire-status",
        tooltipText,
      };
    } else {
      // Active status does not have a tooltip
      return {
        status: t("datagrid.statuses.active"),
        className: "active-status",
      };
    }
  };

  // Determines the CSS class for license support validity based on the support date
  const getSupportValidUntilClass = (supportDate) => {
    const currentDate = new Date();
    const endDate = new Date(supportDate);
    const oneMonthAhead = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );
    const threeMonthsAgo = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 3,
      currentDate.getDate()
    );

    if (endDate < threeMonthsAgo) {
      // If the support date ended more than three months ago, apply a specific CSS class
      return "text-gray-500";
    } else if (endDate < currentDate) {
      // If the support date is in the past but not more than three months ago, apply a different CSS class
      return "text-red-200";
    } else if (endDate < oneMonthAhead) {
      // If the support date is within the next month, apply another CSS class
      return "text-orange-200";
    } else {
      // Otherwise, no specific class is needed
      return "";
    }
  };

  // Determines sorting order for licenses based on their status
  const getStatusSortValue = (status) => {
    const order = {
      [t("datagrid.statuses.aboutToExpire")]: 1,
      [t("datagrid.statuses.active")]: 2,
      [t("datagrid.statuses.expired")]: 3,
      [t("datagrid.statuses.inactive")]: 4,
    };
    return order[status] || 0;
  };

  // Fetches and sets the rows for licenses associated with the active customer ID
  useEffect(() => {
    if (!activeCustomerId) {
      return;
    }

    const newRows = licenses[activeCustomerId]
      ? licenses[activeCustomerId].map((license) => {
          // Call getStatus to get the status object for this license
          const statusObj = getStatus(license.support);

          return {
            id: license.pubhash,
            product: license.product,
            os: getOperatingSystemFromProduct(license.product),
            assignedTo: license.user,
            supportValidUntil: new Date(license.support).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "short", day: "numeric" }
            ),
            family: license.family,
            edition: license.edition,
            licenseType: license.license_type,
            password: license.password,
            s3_indexer: license.s3_indexer,
            status: statusObj.status,
          };
        })
      : [];

    setRows(newRows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCustomerId, licenses, t, licensesLoading, decompilers]);

  // Render function for displaying decompilers in the detail panel of a row
  const RenderDecompilers = ({ row }) => {
    const { decompilers } = useDashboard();

    if (!row || !row.id) {
      return;
    }

    const decompilerData = decompilers[row.id] || [];

    if (!decompilerData.length) {
      return (
        <div className="p-4 flex h-full w-full">
          <div className="no-decompilers">
            <i className="icon icon-search-sm" />
            <p>{t("licenses.noDecompilers")}</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        {decompilerData.map((decompiler) => (
          <div key={decompiler.pubhash} className="flex pl-16 decompilers-row">
            <div>
              <p>{decompiler.pubhash}</p>
              <span className="text-gray-500">
                {decompiler.product_code_nos_nol}
              </span>
            </div>
            <div>-</div>
            <div>
              {new Date(decompiler.support).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Memoized callback to avoid unnecessary re-renders
  const getDetailPanelContent = useCallback(({ row }) => {
    return <RenderDecompilers row={row} />;
  }, []);

  // State for tracking which rows have their detail panels expanded
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  // State for tracking which rows are currently being fetched (loading state)
  const [loadingRowIds, setLoadingRowIds] = useState(new Set());

  // Handles expanding/collapsing of detail panels and fetching data for the expanded rows
  const handleDetailPanelExpandedRowIdsChange = async (newExpandedRowIds) => {
    const newlyExpandedRowIds = newExpandedRowIds.filter(
      (id) => !expandedRowIds.includes(id)
    );
    const updatedLoadingRowIds = new Set(loadingRowIds);
    newlyExpandedRowIds.forEach((id) => updatedLoadingRowIds.add(id));
    setLoadingRowIds(new Set(updatedLoadingRowIds)); // Start loading

    for (const rowId of newlyExpandedRowIds) {
      await fetchDecompilers(activeCustomerId, rowId); // Async data fetch
      updatedLoadingRowIds.delete(rowId); // Stop loading
    }

    setLoadingRowIds(new Set(updatedLoadingRowIds)); // Update loading state
    setExpandedRowIds(newExpandedRowIds); // Update expanded rows state
  };

  const getOperatingSystemFromProduct = (product) => {
    if (!product) return ""; // Handle undefined or null product
    const lastChar = product.charAt(product.length - 1).toLowerCase(); // Get the last character and make it lowercase
    switch (lastChar) {
      case "w":
        return "Windows";
      case "l":
        return "Linux";
      case "m":
        return "MacOs";
      default:
        return "";
    }
  };

  const [columns, setColumns] = useState([]);

  // Columns configuration for the data grid
  useEffect(() => {
    // Define your columns here, incorporating any state-dependent logic
    setColumns([
      {
        field: "id",
        headerName: t("datagrid.columns.licenseId"),
        width: 220,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
          const os = getOperatingSystemFromProduct(params.row.product);
          return (
            <div className="license-name">
              <p>
                {params.row.family} {params.row.edition}{" "}
                {params.row.licenseType} {os}
              </p>
              <span className="text-gray-500">{params.id}</span>
            </div>
          );
        },
      },
      {
        field: "assignedTo",
        headerName: t("datagrid.columns.assignedTo"),
        width: 340,
        disableColumnMenu: true,
      },
      {
        field: "supportValidUntil",
        headerName: t("datagrid.columns.supportValidUntil"),
        width: 345,
        disableColumnMenu: true,
        sortable: true,
        valueGetter: (params) =>
          new Date(params.row.supportValidUntil).getTime(),
        renderCell: (params) => {
          const className = getSupportValidUntilClass(
            params.row.supportValidUntil
          );
          return (
            <span className={className}>
              {new Date(params.row.supportValidUntil).toLocaleDateString(
                "en-US",
                { year: "numeric", month: "short", day: "numeric" }
              )}
            </span>
          );
        },
      },
      {
        field: "status",
        headerName: t("datagrid.columns.status"),
        width: 345,
        disableColumnMenu: true,
        sortable: true,
        valueGetter: (params) =>
          getStatusSortValue(getStatus(params.row.supportValidUntil).status),
        renderCell: (params) => {
          const { status, className, tooltipText } = getStatus(
            params.row.supportValidUntil
          );

          // Check if tooltipText exists and add 'has-tooltip' class along with data attribute
          const additionalClass = tooltipText ? "has-tooltip" : "";
          const tooltipProps = tooltipText
            ? { "data-tooltip-text": tooltipText }
            : {};

          return (
            <span
              className={`status ${className} ${additionalClass}`}
              {...tooltipProps}
            >
              {status}
            </span>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 88,
        sortable: false,
        disableColumnMenu: true,
        align: "center",
        cellClassName: "actions-menu-cell",
        pinned: "right",
        renderCell: (params) => (
          <ActionMenuLicenses
            // row={params.row}
            id={params.id}
            t={t}
            getStatus={getStatus}
          />
        ),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenses, t]);

  // Calculates the height of the detail panel based on the number of decompilers
  const getDetailPanelHeight = (params) => {
    let height;
    if (decompilers[params.id]?.length) {
      const itemHeight = 72;
      const numItems = decompilers[params.id]?.length;
      height = itemHeight * numItems;
    } else {
      height = 112;
    }
    return height;
  };

  const defaultSortModel = [
    {
      field: "status",
      sort: "asc",
    },
  ];

  // Render component UI
  return (
    <>
      <Breadcrumb paths={[{ label: t("licenses.breadcrumb"), link: "" }]} />
      {rows.length && !licensesLoading ? (
        <CustomDataGrid
          key={activeCustomerId}
          rows={rows}
          columns={columns}
          enableSearch={true}
          searchFieldName="assignedTo, edition, family, id, licenseType, status, supportValidUntil, os"
          enablePagination={true}
          pageSizeOptions={[25, 50, 75, 100]}
          pageSize={25}
          getDetailPanelHeight={(params) => getDetailPanelHeight(params)}
          getDetailPanelContent={(params) => getDetailPanelContent(params)}
          onDetailPanelExpandedRowIdsChange={
            handleDetailPanelExpandedRowIdsChange
          }
          detailPanelExpandedRowIds={expandedRowIds}
          loadingRowIds={loadingRowIds}
          sortModel={defaultSortModel}
        />
      ) : (
        ""
      )}

      {licensesLoading ? (
        <div className="data-grid-no-data">
          <div className="spinner" />
          <p className="text-gray-500 mt-3 text-base">
            {t("licenses.loadingData")}
          </p>
        </div>
      ) : (
        ""
      )}
      {!rows.length && !licensesLoading ? (
        <div className="data-grid-no-data">
          <img src={icon} alt="" className="mb-6" />
          <h2 className="font-semibold text-base text-gray-700 mb-8 text-center" dangerouslySetInnerHTML={{__html: t("licenses.emptyState.title", { emailAddress: user.email })}}>
          </h2>
          <p className="text-base text-gray-500">
            {t("licenses.emptyState.desc")}
          </p>
          <a
            href="mailto: my@hex-rays.com"
            className="btn-primary with-icon mt-6"
            rel="noreferrer"
          >
            {t("licenses.emptyState.cta")}
          </a>
        </div>
      ) : (
        ""
      )}
      {!licensesLoading && !activeCustomerId && customers.length > 1 ? (
        <div className="data-grid-no-data">
          <h2 className="text-base text-gray-700 font-bold">
            {t("licenses.welcome")}
          </h2>
          <p className="text-base text-gray-500 mt-2">
            {t("licenses.selectCustomer")}
          </p>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Licenses;
