import React, { useEffect, Fragment, useRef, useState } from "react";
import { Transition } from "@headlessui/react";

const Notification = ({ notification, onClose }) => {
  const onCloseRef = useRef(onClose);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);

  useEffect(() => {
    let timer;
    if (!isHovering) {
      timer = setTimeout(() => {
        if (onCloseRef.current) {
          onCloseRef.current();
        }
      }, 10000);
    }

    return () => clearTimeout(timer);
  }, [isHovering]);

  if (!notification) {
    return null;
  }

  return (
    <div
      className="w-full max-w-sm notification"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Transition
        show={!!notification}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pointer-events-auto overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-start">
              {notification.icon && (
                <i
                  className={`icon ${notification.icon} ${
                    notification.type === "success" ? "text-green-200" : ""
                  } ${notification.type === "loading" ? "text-blue-100" : ""} ${
                    notification.type === "error" ? "text-red-200" : ""
                  }`}
                />
              )}
              <div className="ml-3 w-0 flex-1">
                {notification.title && (
                  <p
                    className={`text-sm font-medium ${
                      notification.type === "success" ? "text-green-200" : ""
                    } ${
                      notification.type === "loading" ? "text-blue-100" : ""
                    } ${notification.type === "error" ? "text-red-200" : ""} ${
                      !notification.type ? "text-gray-900" : ""
                    }`}
                  >
                    {notification.title}
                  </p>
                )}
                <p className="mt-1 text-sm text-gray-500" dangerouslySetInnerHTML={{__html: notification.message}}/>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onClose}
                >
                  <i className="icon-close icon-x-close" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Notification;
