import ReactGA from "react-ga4";

const isDebugMode = process.env.REACT_APP_DEBUG_MODE === "true";
const env = process.env.REACT_APP_BUILD_TYPE;

export const sendEvent = (eventName, eventData) => {

    if (env === 'development') {
        return;
    }

    if (isDebugMode) {
        console.log(`Sending event: ${eventName}`, eventData);
    }

    const gaEventData = {
        action: eventName,
        category: eventData.event_category || "General",
        label: eventData.event_label,
        value: eventData.value ? Number(eventData.value) : 1,
        page: eventData.page_name,
        error_message: eventData.error_message,
    };

    Object.keys(gaEventData).forEach((key) => {
        if (gaEventData[key] === undefined) {
            delete gaEventData[key];
        }
    });

    ReactGA.event(gaEventData);
};
