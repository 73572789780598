// CustomDataGridPagination.jsx
import React, { useState } from 'react';
import { useGridApiContext, useGridSelector, gridPageCountSelector, gridPageSizeSelector, gridPageSelector, gridRowCountSelector } from '@mui/x-data-grid';
import CustomSelect from './CustomSelect';
import { useTranslation } from 'react-i18next';



const CustomDataGridPagination = () => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const page = useGridSelector(apiRef, gridPageSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const [pageSize, setPageSize] = useState(useGridSelector(apiRef, gridPageSizeSelector));


  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    apiRef.current.setPageSize(Number(newPageSize));
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < pageCount) {
      apiRef.current.setPage(newPage);
    }
  };

  const from = rowCount > 0 ? pageSize * page + 1 : 0;
  const to = rowCount > 0 ? Math.min((page + 1) * pageSize, rowCount) : 0;

  return (
    <div className="pagination flex items-center justify-center lg:justify-end px-4 lg:px-6 py-2 lg:py-4 gap-4 lg:gap-6 border-t border-solid border-gray-400">
      <div className="flex items-center">
        <CustomSelect
          options={['10', '25', '50', '100']}
          value={t('datagrid.pagination.rowsPerPage', { pageSize })}
          onChange={handlePageSizeChange}
        />
      </div>
      <div className="flex items-center gap-4 lg:gap-6">
        <span className="text-sm lg:text-base">
          {`${from}-${to} of ${rowCount}`}
        </span>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 0}
          className="disabled:opacity-20 disabled:cursor-not-allowed transition ease-in-out duration-200 hover:opacity-60 leading-none"
        >
          <i className="icon-chevron-left text-xl leading-none" />
        </button>
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page + 1 >= pageCount}
          className="disabled:opacity-20 disabled:cursor-not-allowed transition ease-in-out duration-200  hover:opacity-60 leading-none"
        >
          <i className="icon-chevron-right text-xl leading-none" />
        </button>
      </div>
    </div>
  );
};

export default CustomDataGridPagination;
