import React, { useState, useRef, useEffect } from "react";

/**
 * CustomSelect Component
 *
 * Props:
 * - options: Array of strings representing the data.
 * - value: Currently selected option's value.
 * - onChange: Callback function called when an option is selected.
 */
const CustomSelect = ({ options, value, onChange }) => {
  // State to manage the visibility of the dropdown menu
  const [isOpen, setIsOpen] = useState(false);

  // State to determine the direction of the dropdown menu (upwards or downwards)
  const [menuDirectionUp, setMenuDirectionUp] = useState(false);

  // Reference to the button element
  const buttonRef = useRef(null);

  // Effect to update the menu direction based on available space
  useEffect(() => {
    const updateMenuDirection = () => {
      const rect = buttonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      setMenuDirectionUp(spaceBelow < 160 && spaceAbove > 160);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateMenuDirection);

    // Initial calculation of menu direction
    updateMenuDirection();

    // Remove event listener when component unmounts
    return () => window.removeEventListener("resize", updateMenuDirection);
  }, []);

  // Function to toggle the visibility of the dropdown menu
  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <div className="relative">
      {/* Button to toggle the dropdown */}
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className="px-2 lg:px-4 py-1 lg:py-2 border border-solid border-gray-400 rounded-md outline-none bg-gray-200 appearance-none relative flex items-center gap-2 lg:gap-3 leading-none"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {/* Display the selected option */}
        <span
          className="text-sm lg:text-base leading-snug flex align-center gap-2"
          dangerouslySetInnerHTML={{ __html: value }}
        />
        <i
          className={`icon-chevron-down transition ease-in-out duration-200 text-sm lg:text-xl leading-1 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div
          className={`absolute left-0 lg:left-auto z-10 mt-2 mb-2 w-40 lg:w-full origin-top-right bg-white border border-gray-300 divide-y divide-gray-100 rounded-md outline-none p-4 ${
            menuDirectionUp ? "bottom-full" : "top-full"
          }`}
        >
          <div>
            {/* Render the options as buttons */}
            {options.map((option) => (
              <button
                key={option}
                onClick={() => {
                  onChange(option);
                  toggleDropdown();
                }}
                className="block w-full text-left px-4 py-2 text-sm rounded-md text-gray-700 hover:bg-gray-300 transition ease-in-out duration-200"
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
