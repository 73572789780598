import React, { useState, useMemo, useCallback } from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import TextField from "@mui/material/TextField";
import CustomDataGridPagination from "./CustomDataGridPagination";
import { useTranslation } from "react-i18next"; // Import useTranslation

/**
 * CustomDataGrid Component
 * This component wraps the MUI DataGridPro to include custom functionalities like search and pagination.
 * It is designed to be a reusable data grid component across the application.
 *
 * @component
 * @param {Object[]} rows - Data to be displayed in the grid.
 * @param {Object[]} columns - Column configuration for the grid.
 * @param {boolean} enableSearch - Flag to enable/disable the search feature.
 * @param {string} searchFieldName - Comma-separated string of field names to include in the search.
 * @param {boolean} enablePagination - Flag to enable/disable pagination.
 * @param {number[]} pageSizeOptions - Available options for 'rows per page'.
 * @param {number} pageSize - Default number of rows per page.
 * @param {number} headerHeight - Height of the grid header.
 * @param {number} rowHeight - Height of each row in the grid.
 * @param {function} getDetailPanelContent - Function to render the content of the detail panel for a row.
 * @param {function} getDetailPanelHeight - Function to determine the height of the detail panel for a row.
 * @param {function} onRowClick - Callback function when a row is clicked.
 * @param {function} onDetailPanelExpandedRowIdsChange - Callback when the expanded rows change.
 * @param {Set} detailPanelExpandedRowIds - IDs of rows with expanded detail panels.
 * @param {Set} loadingRowIds - IDs of rows currently loading data.
 */
const CustomDataGrid = ({
  rows,
  columns,
  enableSearch = false,
  searchFieldName = "",
  enablePagination = true,
  pageSizeOptions = [5, 10, 20],
  pageSize = 5,
  headerHeight = 44,
  rowHeight = 72,
  getDetailPanelContent,
  getDetailPanelHeight,
  onRowClick,
  onDetailPanelExpandedRowIdsChange,
  detailPanelExpandedRowIds,
  loadingRowIds,
  sortModel: externalSortModel,
}) => {

  // State for managing search text input by the user.
  const [searchText, setSearchText] = useState("");

  // State for storing filtered rows based on the search criteria.
  const [filteredRows, setFilteredRows] = useState(rows);

  // Accessing the translation function from react-i18next for internationalization.
  const { t } = useTranslation();

  // Handles changes to the search input field, filtering the rows based on the search text.
  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);

    if (searchValue) {
      const searchFields = searchFieldName.split(",").map((field) => field.trim());
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

      // Filters rows where any of the specified fields match the search regex.
      const filteredData = rows.filter((row) =>
        searchFields.some((field) =>
          searchRegex.test(row[field]?.toString() || "")
        )
      );

      setFilteredRows(filteredData);
    } else {
      // Resets the filtered rows to the original rows if the search input is cleared.
      setFilteredRows(rows);
    }
  };

  // Utility function to escape special characters in the search string for regex.
  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  // useMemo hook to optimize performance by memoizing the filtered rows.
  const rowsToDisplay = useMemo(() => filteredRows, [filteredRows]);

  const initialSortModel = externalSortModel || [
    {
      field: 'status',
      sort: 'asc',
    },
  ];
  const [sortModel, setSortModel] = useState(initialSortModel);

  const handleSortModelChange = useCallback((newModel) => {
    setSortModel(newModel);
  }, []);

  return (
    <div className="custom-data-grid">
      {enableSearch && (
        <div className="data-grid-search flex justify-end mb-5">
          <div className="grid-search-wrapper">
            <i className="icon icon-search-sm" />
            {/* Search input field */}
            <TextField
              value={searchText}
              onChange={handleSearch}
              placeholder={t("general.searchPlaceholder")}
            />
          </div>
        </div>
      )}
      {/* DataGrid component */}
      <DataGridPro
        rows={rowsToDisplay}
        columns={columns}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize } },
          pinnedColumns: { right: ['actions'] }
        }}
        pagination={enablePagination}
        disableRowSelectionOnClick
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        slots={{
          columnSortedAscendingIcon: () => <i className="icon icon-chevron-up" />,
          columnSortedDescendingIcon: () => <i className="icon icon-chevron-down" />,
          columnUnsortedIcon: () => <i className="icon icon-chevron-selector-vertical" />,
          detailPanelExpandIcon: (params) => <i className="icon icon-plus-square text-gray-500" />,
          detailPanelCollapseIcon: () => <i className="icon icon-minus-square text-gray-500" />,
          footer: CustomDataGridPagination,
        }}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        onRowClick={onRowClick}
        onDetailPanelExpandedRowIdsChange={onDetailPanelExpandedRowIdsChange}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        getRowClassName={(params) => {
          return `${loadingRowIds.has(params.id) ? 'loading' : 'not-loading'}`;
        }}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
      />
    </div>
  );
};

export default CustomDataGrid;