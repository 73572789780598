// Dashboard.js
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Licenses from "./Licenses";
import Sidebar from "./Sidebar";
import Quotes from "./Quotes";
import packageJson from '../../../package.json';
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="mobile-header flex border-b border-gray-300 bg-gray-200 lg:hidden">
          <button
            onClick={() => setSidebarOpen(true)}
            className="p-4 text-gray-500 focus:outline-none lg:hidden border-r border-gray-300 text-xl leading-none"
          >
            <i className="icon-layout-left text-xl leading-none text-gray-500" />
          </button>
        </div>
        <main className="flex-1 overflow-x-hidden overflow-y-auto dotted-background dashboard-main p-6">
          <Routes>
            <Route path="/licenses" element={<Licenses />} />
            <Route path="/quotes" element={<Quotes />} />
          </Routes>
          <p className="mt-6 text-gray-500 text-xs text-right">{t("general.versionText", { version: packageJson.version })}</p>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
