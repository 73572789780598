import React, { createContext, useContext, useState } from "react";
import Notification from "../components/general/Notification";

// Create a context for notifications
const NotificationContext = createContext();

/**
 * Custom hook for accessing the notification context.
 *
 * @returns {Object} - The notification context.
 */
export const useNotification = () => {
  return useContext(NotificationContext);
};

/**
 * NotificationProvider component for managing notifications.
 *
 * @param {Object} children - React components wrapped by the NotificationProvider.
 * @returns {JSX.Element} - The rendered NotificationProvider component.
 */
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  /**
   * Add a new notification to the list of notifications.
   *
   * @param {Object} notification - The notification to be added.
   */
  const addNotification = (notification) => {
    const id = Date.now();
    setNotifications((prevNotifications) => [...prevNotifications, { ...notification, id }]);
  };

  /**
   * Remove a notification with the specified ID from the list of notifications.
   *
   * @param {number} id - The ID of the notification to be removed.
   */
  const removeNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter(notification => notification.id !== id)
    );
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <div className="notifications-container fixed left-3 bottom-3 flex flex-col-reverse gap-3 w-[280px] md:w-[380px] h-auto">
        {notifications.map((notification) => (
          <Notification key={notification.id} notification={notification} onClose={() => removeNotification(notification.id)} />
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
