import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import logo from "../../assets/images/logo.svg";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { useDashboard } from "../../context/DashboardContext";
import { components } from 'react-select';
import { sendEvent } from '../../utils/analyticsService';



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [navigation, setNavigation] = useState([
    { name: t("sidebar.licenses"), href: "/dashboard/licenses", icon: "icon-key-02", current: false },
  ]);

  const { customers, activeCustomerId, setActiveCustomerId, resetSession } = useDashboard();
  const { signOut } = useAuth();

  const [showCustomersSelect, setShowCustomersSelect] = useState(false);

  useEffect(() => {
    const newNavigation = navigation.map((item) => ({
      ...item,
      current: location.pathname === item.href,
    }));
    setNavigation(newNavigation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (customers.length > 1) {
      setShowCustomersSelect(true);
    }
  }, [customers]);

  const handleSignOut = async () => {
    await signOut();
    resetSession();
  };

  const handleNavigation = (href, itemName) => {
    navigate(href);
    const updatedNavigation = navigation.map((item) => ({
      ...item,
      current: item.name === itemName,
    }));
    setNavigation(updatedNavigation);
    setSidebarOpen(false);
  };

  // Function to handle customer selection from dropdown
  const handleCustomerChange = (selectedOption) => {
    const customerId = selectedOption.value;
    sendEvent("Select customer", {
      event_trigger: "Change on Select customer",
      event_category: "Select customer",
      event_label: "Select customer",
      page_name: "dashbord",
    });
    setActiveCustomerId(customerId);
  };

  // Preparing customer options for Select component
  const customerOptions = customers.map(customer => ({
    value: customer.id,
    label: customer.long_name // Assuming each customer has a 'name' property
  }));

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="icon-chevron-selector-vertical" />
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: '54px',
      fontSize: '16px',
      lineHeight: '24px',
      borderColor: state.isFocused ? '#1F1F1F' : '#DCDCDC',
      boxShadow: state.isFocused ? '0px 0px 0px 1px rgba(31, 31, 31, 0.30)' : 'none',
      borderRadius: '4px',
      padding: '11px 0',
      color: state.isFocused ? '#fff' : '#191919',

      '&:hover': {
        boxShadow: '0px 0px 0px 1px rgba(31, 31, 31, 0.30)',
        borderColor: '#1F1F1F'
      },
      ...(state.hasValue && {
        boxShadow: '0px 0px 0px 1px rgba(31, 31, 31, 0.30)',
        borderColor: '#1F1F1F',
      }),
    }),
    valueContainer: (base, state) => ({
      ...base,
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '16px',
      paddingRight: '0',
    }),
    input: (base, state) => ({
      ...base,
      margin: '0',
      color: '#1F1F1F',
      fontSize: '16px',
      lineHeight: '24px',
      boxShadow: 'none'
    }),
    placeholder: (base, state) => ({
      ...base,
      margin: '0',
      color: '#1F1F1F',
      display: state.isFocused || state.hasValue ? 'none' : 'block',

    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '32px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: '14px',
      lineHeight: '20px',
      padding: '8px 16px',
      borderRadius: '6px',
      background: state.isSelected ? '#F3F4F6' : '#fff',
      color: state.isSelected ? '#191919' : '#191919',
      cursor: state.isSelected ? 'default' : 'pointer',
      '&:hover': {
        background: '#F3F4F6'
      },
      '&:active': {
        background: '#F3F4F6',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '16px',
      color: '#1F1F1F',
      fontSize: '18px'
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '6px',
      marginTop: '10px',
      border: '1px solid #F3F4F6',
      background: '#FFF',
      boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)',
      overflow: 'hidden',
      padding: '16px'
    }),
    menuList: (base) => ({
      ...base,
      padding: 0
    }),
  };

  const sidebarContent = (
    <>
      <div className="flex h-11 shrink-0 items-center">
        <img className="h-11 w-auto" src={logo} alt="My Hex-Rays Account" />
      </div>
      <nav className="flex-1">

        {showCustomersSelect && (
          <Select
            options={customerOptions}
            onChange={handleCustomerChange}
            placeholder={t("sidebar.searchCustomer")}
            value={customerOptions.find(option => option.value === activeCustomerId)}
            className="mb-6 customer-select"
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            styles={customStyles}
          />
        )}

        <ul className="flex flex-col gap-y-3">
          {navigation.map((item) => (
            <li key={item.name}>
              <button
                onClick={() => handleNavigation(item.href, item.name)}
                className={classNames(
                  item.current
                    ? "bg-gray-300"
                    : "text-gray-700 hover:text-gray-700 hover:bg-gray-50",
                  "group flex gap-x-3 rounded-md px-4 py-2 text-base leading-6 font-semibold items-center w-full"
                )}
              >
                {item.icon && (
                  <i className={`icon ${item.icon} leading-1 text-xl`} />
                )}
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <div className="group mt-auto flex items-center justify-between rounded-md bg-gray-300 p-1 gap-3 cursor-default">

        <div className="flex items-center gap-3">
          <div className="flex-shrink-0">
            <span className="block h-12 w-12 rounded-md flex items-center justify-center text-gray-500 text-base avatar">
              {user.email[0].toUpperCase()}
            </span>
          </div>
          <div className="email-wrapper">
            <p className="text-xs text-gray-500 truncate" title={user.email}>
              {user.email}
            </p>
          </div>
        </div>
        <span
          className="text-gray-500 text-xl leading-1 mr-1 cursor-pointer transition ease-in-out duration-200 group-hover:opacity-50 leading-none flex"
          onClick={() => {handleSignOut()}}
        >
          <i className="icon icon-log-out-01 leading-none text-xl" />
        </span>
      </div>
    </>
  );

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 lg:hidden"
          onClose={() => setSidebarOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-lg"
              onClick={() => setSidebarOpen(false)}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-300"
            enterFrom="translate-x-[-100%]"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-[-100%]"
          >
            <Dialog.Panel className="relative flex-1 w-full max-w-xs flex-col bg-white p-6 shadow-xl h-full">
              <i className="icon-x-close text-xl leading-none text-gray-500 absolute top-2.5 right-2.5" onClick={() => setSidebarOpen(false)} />
              <div className="flex flex-col h-full justify-between gap-14">
                {sidebarContent}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col sidebar border-r border-gray-300 bg-gray-200 p-6">
        <div className="flex flex-col h-full justify-between gap-14">
          {sidebarContent}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
