import React from "react";
import { Link } from "react-router-dom";

/**
 * Breadcrumb component for displaying navigation paths.
 *
 * @param {object} props - Props for the Breadcrumb component.
 * @param {Array} props.paths - An array of path objects containing label and optional link.
 * @returns {JSX.Element} - The rendered Breadcrumb component.
 */
const Breadcrumb = ({ paths }) => {
  return (
    <nav className="breadcrumb w-full pb-2 bg-gray-300 flex items-center">
      <ul>
        {paths.map((path, index) => (
          <li key={index} className="py-1 px-6 bg-gray-100 font-medium text-xl text-gray-700">
            {path.link ? (
              <Link to={path.link}>{path.label}</Link>
            ) : (
              <span>{path.label}</span>
            )}
            <span className="ml-1.5">/</span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
