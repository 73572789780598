import React from "react";

const Loader = () => {
  return (
    <div className="loader-wrapper flex items-center justify-center h-screen">
      <div className="loader-container">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="56"
            height="56"
            rx="16"
            fill="url(#paint0_radial_39_205)"
          />
          <path
            d="M45.8406 30.4812L24.8721 42.9886L24.8721 17.9886L45.8406 30.4812Z"
            fill="url(#paint1_linear_39_205)"
          />
          <path
            d="M10.1594 26.0949L29.1594 13.0114L29.1594 38.0114L10.1594 26.0949Z"
            fill="url(#paint2_radial_39_205)"
          />
          <path
            d="M29.1511 37.9887L24.8721 42.9886L24.8721 35.3302L29.1511 37.9887Z"
            fill="url(#paint3_linear_39_205)"
          />
          <defs>
            <radialGradient
              id="paint0_radial_39_205"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(7.60412 5.88192) rotate(47.4896) scale(59.6936)"
            >
              <stop stopColor="#3C3C3C" />
              <stop offset="1" stopColor="#191919" />
            </radialGradient>
            <linearGradient
              id="paint1_linear_39_205"
              x1="36.7358"
              y1="29.8057"
              x2="30.4879"
              y2="29.5291"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="#B8B9BA" />
            </linearGradient>
            <radialGradient
              id="paint2_radial_39_205"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(29.1594 29.3523) rotate(-141.41) scale(14.2151 43.0869)"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="#E0E4E8" />
            </radialGradient>
            <linearGradient
              id="paint3_linear_39_205"
              x1="26.5047"
              y1="39.1594"
              x2="26.5196"
              y2="37.0279"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="#BCBEC1" />
            </linearGradient>
          </defs>
        </svg>

        <div className="glow-effect">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
