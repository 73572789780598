import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import apiService from "../utils/apiService";
import Loader from "../components/general/Loader";
import { useNotification } from "./NotificationContext";
import supabase from "../utils/supabase";
import { useAuth } from "./AuthContext";
import { sendEvent } from "../utils/analyticsService";

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [licenses, setLicenses] = useState({});
  const [customers, setCustomers] = useState([]);
  const [activeCustomerId, setActiveCustomerId] = useState(null);
  const { addNotification } = useNotification();
  const [licensesLoading, setLicensesLoading] = useState(false);

  const [decompilers, setDecompilers] = useState({});

  const { userSession } = useAuth();

  const fetchLicensesForCustomer = async (customerId) => {
    setLicensesLoading(true);

    // Check if the licenses for this customer ID already exist in state;
    if (licenses[customerId] && licenses[customerId].length > 0) {
      setTimeout(() => {
        setLicensesLoading(false);
      }, 1000);
      return;
    }
    try {
      const licensesData = await apiService.getLicenses(customerId);
      setLicenses((prev) => ({ ...prev, [customerId]: licensesData || [] }));
      setLicensesLoading(false);
    } catch (error) {
      console.error("Error fetching licenses:", error);
      sendEvent("Error", {
        event_trigger: "Fetching licenses",
        event_category: "Error",
        event_label: "Error fetching licenses",
        error_message: error,
      });
      setLicensesLoading(false);
      addNotification({
        title: "Error",
        message: `Error fetching licenses: ${error.message}`,
        icon: "icon-alert-circle",
        type: "error",
      });
    }
  };

  const updateCurrentCustomer = (newId) => {
    setActiveCustomerId(newId);
    fetchLicensesForCustomer(newId);
  };

  const fetchDecompilers = async (customerId, pubhash) => {
    // Check if decompilers for this license already loaded
    if (decompilers[pubhash]) {
      return;
    }
    try {
      const decompilersData = await apiService.getDecompilers(
        customerId,
        encodeURIComponent(pubhash)
      );
      // Update the decompilers state with new data
      setDecompilers((prevDecompilers) => ({
        ...prevDecompilers,
        [pubhash]: decompilersData,
      }));
    } catch (error) {
      console.error("Error fetching decompilers:", error);
      sendEvent("Error", {
        event_trigger: "Fetching decompilers",
        event_category: "Error",
        event_label: "Error fetching decompilers",
        error_message: error,
      });
    }
  };

  const updateLicenseS3Indexer = (pubhash, newS3IndexerEntry) => {
    setLicenses((prevLicenses) => {
      const updatedLicenses = { ...prevLicenses };

      Object.keys(updatedLicenses).forEach((customerId) => {
        const customerLicenses = updatedLicenses[customerId];
        const licenseIndex = customerLicenses.findIndex(
          (license) => license.pubhash === pubhash
        );

        if (licenseIndex !== -1) {
          const licenseToUpdate = customerLicenses[licenseIndex];
          const s3IndexerIndex = licenseToUpdate.s3_indexer.findIndex(
            (item) =>
              item.pubhash === newS3IndexerEntry.pubhash &&
              item.type === newS3IndexerEntry.type
          );

          if (s3IndexerIndex !== -1) {
            // Update existing s3_indexer entry
            licenseToUpdate.s3_indexer[s3IndexerIndex] = {
              ...licenseToUpdate.s3_indexer[s3IndexerIndex],
              ...newS3IndexerEntry,
            };
          } else {
            // Insert new s3_indexer entry
            licenseToUpdate.s3_indexer.push(newS3IndexerEntry);
          }

          // Update the license in customerLicenses
          customerLicenses[licenseIndex] = licenseToUpdate;
        }
      });

      if (newS3IndexerEntry.updated_at !== null) {
        addNotification({
          title: `The installer for ${pubhash} is ready`,
          message: "You can download it now!",
          icon: "icon-check-circle",
          type: "success",
        });
      }

      return updatedLicenses;
    });
  };

  const [hasFetchedCustomers, setHasFetchedCustomers] = useState(false);
  const hasFetchedCustomersRef = useRef(hasFetchedCustomers);

  useEffect(() => {
    hasFetchedCustomersRef.current = hasFetchedCustomers;
  }, [hasFetchedCustomers]);

  useEffect(() => {
    (async () => {
      const { data: session, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error getting session:", error);
        sendEvent("Error", {
          event_trigger: "Fetching getting session",
          event_category: "Error",
          event_label: "Error getting session",
          error_message: error,
        });
        return;
      }
      const fetchData = async () => {
        setLoading(true);
        if (session.session) {
          try {
            const customersData = await apiService.getCustomers();
            setCustomers(customersData || []);
            if (customersData.length === 1) {
              const customerId = customersData[0].id;
              setActiveCustomerId(customerId);
              await fetchLicensesForCustomer(customerId);
            }
            setHasFetchedCustomers(true);
          } catch (error) {
            console.error("Error fetching customers:", error);
            sendEvent("Error", {
              event_trigger: "Fetching getting customers",
              event_category: "Error",
              event_label: "Error getting customers",
              error_message: error,
            });
            addNotification({
              title: "Error",
              message: `Error fetching data: ${error.message}`,
              icon: "icon-alert-circle",
              type: "error",
            });
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      };
      // Check if there is a session and if data hasn't been fetched yet
      if (session && !hasFetchedCustomersRef.current) {
        await fetchData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession]);

  useEffect(() => {
    if (userSession && userSession.user && userSession.user.id) {
      const processPayloadAndUpdateLicense = (payload) => {
        const { new: newRecord } = payload;
        const pubhash = newRecord.pubhash;
        const newS3IndexerEntry = {
          type: newRecord.type,
          suffix: newRecord.suffix,
          updated_at: newRecord.updated_at,
          pubhash: newRecord.pubhash,
        };
        updateLicenseS3Indexer(pubhash, newS3IndexerEntry);
      };

      supabase
        .channel("s3_indexer")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "s3_indexer",
            filter: `auth_user_id=eq.${userSession?.user?.id}`,
          },
          (payload) => {
            processPayloadAndUpdateLicense(payload);
          }
        )
        .subscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession]);

  const resetSession = () => {
    // Reset all state variables to their initial states
    setLicenses({});
    setCustomers([]);
    setActiveCustomerId(null);
    setLicensesLoading(false);
    setDecompilers({});
    setHasFetchedCustomers(false);
  };

  return (
    <DashboardContext.Provider
      value={{
        licenses,
        customers,
        activeCustomerId,
        setActiveCustomerId: updateCurrentCustomer,
        fetchDecompilers,
        licensesLoading,
        decompilers,
        resetSession
      }}
    >
      {!loading ? children : <Loader />}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
};
